import { Table } from "antd";
import React, { useState, useEffect } from "react";
import moment from "moment";

function MonthlySatta({ gamedata, dropValue }) {
  const [data, setData] = useState([]);
  let [columns, setcolumn] = useState([]);
  var date = new Date();
  const currentMonth = moment().tz("Asia/Kolkata").month();
  const currentYear = moment().tz("Asia/Kolkata").year();
  const numberDaysMonth = moment().tz("Asia/Kolkata").daysInMonth();
  const [gameResult, setGameResult] = useState([]);
  useEffect(() => {
    if (gamedata) {
      setData(gamedata);
    }
  }, [gamedata]);

  var selectMonthDrop;
  useEffect(() => {
    if (dropValue) {
      setGameResult(dropValue);
      // console.log('gameResult', gameResult)
      if (gameResult == "") {
        selectMonthDrop = moment().tz("Asia/Kolkata").format("MMMM");
        // console.log('if======>', selectMonthDrop)
      } else {
        selectMonthDrop = moment(
          `${dropValue?.year}-${dropValue?.month}-01`
        ).format("MMMM");
        // console.log('else======>', selectMonthDrop)
      }
      // console.log(gameResult);
      // console.log()
    } else {
    }
  });
  useEffect(() => {
    if (data.length > 0) {
      let array = Object.keys(data[0]);
      for (let i = 0; i < array.length; i++) {
        array[i] = {
          title: array[i] === "day" ? selectMonthDrop : array[i],
          dataIndex: array[i],
          key: array[i],
        };
      }
      setcolumn(array);
    }
  }, [data]);
  useEffect(() => {
    fetch("https://api.sattakingvip.co.in/getmonthdata", {
      method: "POST", // or 'PUT' depending on your requirements
      headers: {
        "Content-Type": "application/json", // specify the content type
      },
      body: JSON.stringify({
        month: currentMonth + 1,
        year: currentYear,
        gameName: "",
        result: "",
        days: numberDaysMonth,
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        setData(json);
      })
      .catch((error) => console.error(error));
  }, []);

  return (
    <div className="monthYrTbl pt-3" id="monthTable">
      {!(dropValue == null) && (
        <h5
          className="text-center bg-dark text-white p-2"
          style={{ display: "block" }}
        >
          {/* {dropValue?.selectedOption?.value} MONTHLY RECORD CHART {dropValue?.selecYear?.value} */}
          {dropValue?.gameName} MONTHLY RECORD CHART {selectMonthDrop} -{" "}
          {dropValue?.year || currentYear}
        </h5>
      )}
      <div className="table-responsive" id="scrollbar1">
        <Table dataSource={data} columns={columns} pagination={false} />;
      </div>
    </div>
  );
}

export default MonthlySatta;
