import React from "react";
import { Link } from "react-router-dom";
function FooterButton() {
  return (
    <div class="bottom-link pt-2">
      <Link class="bottom" to="/" title="Satta king">
        SATTA KING
      </Link>
      <Link class="bottom" to="/about" title="Satta king">
        ABOUT
      </Link>
      <Link class="bottom" to="/contact" title="Satta king">
        CONTACT
      </Link>
      <Link class="bottom" to="/faq" title="Satta king">
        FAQ
      </Link>
      <Link class="bottom" to="/disclaimer" title="Satta king">
        DISCLAIMER
      </Link>
      <Link class="bottom" to="/privacypolicy" title="Satta king">
        PRIVACY POLICY
      </Link>
      <br />
      <br />
    </div>
  );
}

export default FooterButton;
