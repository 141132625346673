const FAQ = () => {
  return (
    <>
      <div className="faq mt-3 mb-3">
        <h3>
          <b>What is satta king ?</b>
        </h3>
        <p>
          In Satta King, there are various numbers written on slips, out of
          which the lottery comes out the handiest one precise number. The
          people bet on the selected number among 00 and 99. If your choose
          number has been declared as in result, then you'll be rewarded the
          lottery amount and entitled as "Satta King". If not, you'll lose all
          the amount which you had bet. The window for the opening of the random
          number is pre-set through the company. Earlier Satta King was limited
          to be performed traditionally i.e. "khaiwal" used to collect the
          betting amount from the people and use to vicinity the bet for them.
          Thus, it was very hectic and time taking. Now, after digitization when
          everything was going online. Satta King Games has also chosen to go
          digital. You can now play it on numerous websites. Although people
          prefer to play online, tho traditional method is still alive. khaiwal
          are still there, to help place the bet.
        </p>

        <h3>
          <b>How much profit can I make playing Satta king ?</b>
        </h3>
        <p>
          The amount completely depends on your bet and type of bet you did.
          Suppose you place a bet for 'haroop' then you will get 9x in return,
          similarly if you place a bet for 'jodi' your winning amount will be
          90x of your betting amount. Ultimately, it means that your winning
          amount will be multiplied by 9 or 90 respectively. In addition, let's
          assume you put 10 rupees on the bet, this will win 90 rupees in return
          if it was placed for "haroop" or 900 if it was placed for"jodi". There
          is no limit on the amount of winnings, it is entirely up to your risk
          tolerance. Moreover, you can put any amount as per your risk tolerance
          and as big as you can. In layman's language the more you can risk, the
          more you can make. So you can win unlimited amount just by playing
          right number and winning.
        </p>

        <h3>
          <b>What is Satta Matka King ?</b>
        </h3>

        <p>
          Satta Matka is number based game, where you've to predict and make a
          bet on a number which you think this is going to come out of pot
          History of this game dates back to 1940s, during that time the workers
          had nothing to do apart from their daily wage work. They came up with
          the idea of betting on opening and closing of cotton prices. They see
          their fortune in it. Workers found easy way up. However, New York
          Cotton exchange found out about that and banned all the betting on
          cotton trades. Although, they were not able to ban betting. Due to
          lucrative addiction towards the game people came out with new ways to
          gamble. Satta Matka was one of them. Satta matka is derived from two
          words "Satta" means gamble and "Matka" means earthen pot. As the name
          says, chit of numbers is put inside the earthen matka and folks had to
          predict and bet on the winning number(number drawn out through the
          pot). This became very popular over the period of the time and running
          till the time we are writing this.
        </p>

        <h3>
          <b>What is the difference between Satta Matka and Satta King ?</b>
        </h3>

        <p>
          There is a lot of confusion around people regarding Satta Matka and
          Satta King. They are no different Satta matka is the traditional
          number based game, and Satta King was the title that was used to give
          to the winner of any kind of Satta. However over the period of the
          time, people became so much fond of the title, end up calling the game
          as Satta King. Nowadays people are more aware Satta King than Satta
          Matka.
        </p>

        <h3>
          <b>Where should I play Satta King ?</b>
        </h3>

        <p>
          There are various of ways to play Satta King. The most and preferred
          way of playing is by online websites. After digitalization took place,
          numerous of website has come that allows you to play Satta King on it.
          On the hand "khaiwal's" are still there to help you to play it
          offline. You just have to find a trustworthy khaiwal to place your bet
          and play.
        </p>

        <h3>
          <b>Who are khaiwals ?</b>
        </h3>

        <p>
          "Khaiwals" are basically representative of the company who organizes
          the Satta King Game. They take the staked amount from you with your
          predicted number and place a bet on your behalf. After the game,
          khaiwals are responsible for giving you the winning amount. In other
          if we summarize Khaiwals, they act as a broker between you and the
          company of organizers.
        </p>

        <h3>
          <b>Is it legal to play Satta King ?</b>
        </h3>

        <p>
          Indian Judiciary has banned all type of betting, long ago in the 1960s
          or 70s stating it as unfair. Tho, some games are still legal in few
          parts of Indian Province i.e Sikkim. People are allowed to place bet
          on those games only. What are some biggest win people had in Satta
          King? There is no such record available. Daily crores of bets take
          place and daily people win big. And everything is kept off the hook so
          no one knows.
        </p>

        <h3>
          <b>Can I get rich playing Satta King ?</b>
        </h3>

        <p>
          Yes, you can. If you play with precision and luck favors you, you can
          surely become rich. However, one shouldn't be completely dependent on
          satta to become rich. They'll end up losing what they have. Satta may
          have helped some to become rich, but it has killed more. If you're
          gambling out of fun, with money you don't need, then it's fine. But
          when betting becomes an addiction, it's injurious. One shouldn't use
          their bread and butter to gamble.
        </p>

        <h3>
          <b>What are Jodi and Dhara ?</b>
        </h3>

        <p>
          Jodi and Dhara are satta terms. Used to place bets on numbers. As the
          name goes, Jodi means double, and Dhara means single. If someone is
          betting on Jodi, it means they are betting on two numbers. Similarly,
          if someone is betting on dhara, they are betting on a single number.
          Winnings also move the same. If someone wins dhara their betting
          amount gets 9x, and in Jodi, it gets 90x.
        </p>

        <h3>
          <b>What are some types of Satta king ?</b>
        </h3>

        <p>
          As said in the above article, satta king is an umbrella. Lots of game
          happens there. Satta Matka, Gali, Disawar, Faridabad Satta, Haridwar
          Satta, Lucknow Gold, Lal Kila, Tejupur Morning, Taj, Mumbai bazaar,
          etc are a few of them.
        </p>

        <h3>
          <b>What are the basic requirements to play Satta king ?</b>
        </h3>

        <p>
          There are no such requirements. The only thing that needs is luck and
          money. Apart from that you just have to find the Khaiwal or web/app
          run by them. In case you're placing a bet online, you'll need a phone
          with an internet connection, the name of the app, or the web URL of
          the platform. You'll need to register there and add funds from there.
          As soon as the fund is added you're ready to place the bet.
        </p>

        <h3>
          <b>Where should I find a khaiwal ?</b>
        </h3>

        <p>
          Since 2005, after the police raids, it has become tough to find them.
          Earlier, Tea vendors, Paan shop used to work as khaiwal. Though it
          used to happen in the past but habit doesn't change easily. You can
          still find a few of them, you just have to find the right shop.
        </p>
      </div>
    </>
  );
};

export default FAQ;
