function Disclaimer() {
  return (
    <div className="col-12   text-center disclaimer container-fluid mt-3 mb-3">
      <div className="disContent pt-3">
        <h1>
          <b>Disclaimer</b>
        </h1>
        <br />
        <p>
          Satta king is a non commerical website. Viewing This WebSite Is Your
          Own Risk. All The Information Shown On Website Is Sponsored And We
          Warn You That Matka Gambling/Satta king May Be Banned Or Illegal In
          Your Country. We Don't Run Any Satta Matka Gambling And Don't Have Any
          Connection With Satta Makta/ Satta King Company In Any Way. If Some
          One Ask You To Pay Money Please Take Decision as per your
          understanding. We will be Not Responsible For Any Issues Or Scam. We
          Respect All Country Rules/Laws. If You Not Agree With Our Site
          Disclaimer. Please Quit Our Site Right Now. Thanks
        </p>
      </div>
    </div>
  );
}

export default Disclaimer;
