import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";

import DetailAcord from "./js/detailAccord";

import App from "./App";
import FooterButton from "./js/footerButton";

const RootComponent = () => {
  return (
    <div className="main-div">
      <div className="container-fluid">
        <App />
        {/* <DetailAcord /> */}
        <FooterButton />
      </div>
    </div>
  );
};

ReactDOM.render(
  <Router>
    <RootComponent />
  </Router>,
  document.getElementById("root")
);
