import "./App.css";
import moment from "moment";
import "moment-timezone";
import React, { useState, useEffect } from "react";
import Disclaimer from "./js/disclaimer";
import FAQ from "./js/faq";
import ContactUs from "./js/contact";
import PrivacyPolicy from "./js/privacy";
import AboutUs from "./js/about";
import trackVisitor from "./utilities/tracker";
import DaySattaResult from "./js/daySattaResult";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

function App() {
  var currentDate = moment(new Date()).tz("Asia/Kolkata").format("lll");
  const location = useLocation();
  const isDisc = location.pathname.includes("/disclaimer");
  const isContact = location.pathname.includes("/contact");
  const isPrivacy = location.pathname.includes("/privacypolicy");
  const isAbout = location.pathname.includes("/about");
  const isFaq = location.pathname.includes("/faq");

  var currentDate = moment().tz("Asia/Kolkata").format("YYYY-MM-DD");
  const currentTime = moment().format("HH:mm");
   const [data, setData] = useState([]);
  const [datagame, setDataFor] = useState([]);
  var prevDate = moment()
    .subtract(1, "days")
    .tz("Asia/Kolkata")
    .format("YYYY-MM-DD");
  useEffect(() => {
    trackVisitor();
  });

useEffect(() => {
  fetch("https://api.sattakingvip.co.in/getData", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      game_name: "",
      curr_date: currentDate,
      prev_date: prevDate,
      open_time: "market_sunday_time_open",
    }),
  })
    .then((response) => response.json())
    .then((json) => {
      // Sort data based on open_time
      const sortedData = json.sort((a, b) => {
        const timeA = moment(a.open_time, "HH:mm");
        const timeB = moment(b.open_time, "HH:mm");
        return timeA.diff(timeB);
      });

      // Set sorted data into state
      setData(sortedData);
    })
    .catch((error) => console.error(error));
}, [currentDate, prevDate]);

 useEffect(() => {
   if (data?.length > 0) {
     // Convert current time to a moment object for comparison
     const currentMoment = moment(currentTime, "HH:mm");

     // Process and filter the data
     const processedData = data.map((item) => {
       const itemTime = moment(item.open_time, "HH:mm");
       const resultAvailable = item?.curr_date?.result ? true : false;

       return {
         gameName: item.game_name,
         result: resultAvailable ? item?.curr_date?.result : "wait",
         openTime: item.open_time,
         isAvailable: resultAvailable,
         itemTime: itemTime,
       };
     });

     // Sort the processed data by open_time
     const sortedProcessedData = processedData.sort((a, b) => {
       return a.itemTime.diff(b.itemTime);
     });

     // Separate records into those with available results and those with "wait"
     const availableResults = sortedProcessedData.filter(
       (item) => item.isAvailable
     );
     const upcomingRecords = sortedProcessedData.filter(
       (item) => !item.isAvailable
     );

     // Determine the records to display
     let recordsToDisplay = [];

     if (availableResults.length > 0) {
       // Show available results and include records up to the next upcoming record
       recordsToDisplay = [...availableResults];

       const lastAvailableIndex = sortedProcessedData.indexOf(
         availableResults[availableResults.length - 1]
       );
       const nextRecord = sortedProcessedData[lastAvailableIndex + 1];
       if (nextRecord) {
         recordsToDisplay.push(nextRecord);
       }
     } else {
       // No available results, show up to 3 upcoming records with "wait"
       recordsToDisplay = [...upcomingRecords.slice(0, 3)];
     }

     // Ensure only 3 records are shown
     if (recordsToDisplay.length > 3) {
       // Remove the oldest record if more than 3 records are present
       recordsToDisplay = recordsToDisplay.slice(-3);
     }

     // Update state with the processed and limited data
     setDataFor(recordsToDisplay);

     // Debugging log
   }
 }, [data, currentTime]);

  return (
    <div className="App">
      <div className="col-12 homeHeader">
        <div className="row main-row">
          <div className="col-md-4 col-sm-12 text-center text-danger fw-b link">
            <a href="/">HOME</a>
          </div>
          <div className="col-md-4 col-sm-12 text-center text-danger fw-b link">
            <a href="http://sattasport.co/">SATTA KING</a>
          </div>
          <div className="col-md-4 col-sm-12 text-center text-danger fw-b link">
            <a href="http://satta-king-black.co.in/">SATTA BLACK</a>
          </div>
        </div>
      </div>
      <marquee className="text-warning">
        Satta King Result, SattaKing, Satta, Satta.Com, Satta Com, Gali Result,
        Satta News, Today Satta Result, Live Satta King, Satta Aaj Ka Satta
        Result, Gali Result Today{" "}
      </marquee>
      <div className="col-12 text-center header-heading">
        <h6>SATTA KING GAME RECORD CHART</h6>
        <h1>SATTA KING</h1>
      </div>

      <div className="text-center currentBanner mt-3">
        <h6>हा भाई यही आती हे सबसे तेज़ खबर रूको और धेखो</h6>
        {datagame?.map((todayData, index) => (
          <div key={index} className="game">
            <p className="mb-0">{todayData?.gameName}</p>
            <button className=" result-button blinking-text">
              {todayData?.result || ""}
            </button>
          </div>
        ))}
      </div>
      <p className="text-center currentBanner">
        <a href="/">
          <button className="result-button">Result Refresh</button>{" "}
        </a>
      </p>
      <DaySattaResult dayGameData={data} />
      {isDisc && <Disclaimer />}
      {isContact && <ContactUs style={{ display: "none" }} />}
      {isPrivacy && <PrivacyPolicy style={{ display: "none" }} />}
      {isAbout && <AboutUs style={{ display: "none" }} />}
      {isFaq && <FAQ style={{ display: "none" }} />}
    </div>
  );
}

export default App;
