const AboutUs = () => {
  return (
    <>
      <div className="about mt-3 mb-3">
        <h3>
          <b>About Us</b>
        </h3>
        <p>
          Satta King is a portal where you will get fatest result of Gali,
          Deshawar, faridabad, Ghaziybad and Satta king other games. We also
          have yearly and monthly wise recort chart of Gali, Deshawar,
          faridabad, Ghaziybad game. You can check all that record and can make
          a prediction of upcoming number.
          <br /> <br /> On this website you will find contact number of many
          predictor who sell their predicted game. We don't give guarantee about
          any predictor posted their contact number on this website.
          <br />
          <br /> All The Information Shown On Website Is Sponsored And We Warn
          You That Matka Gambling/Satta May Be Banned Or Illegal In Your
          Country. We Dont Run Any Satta Matka Gambling And Dont Have Any
          Connection With Satta Makta/ Satta King Company In Any Way. If Some
          One Ask You To Pay Money Please make money as per your understanding.
          We Are Not Responsible For Any Issues Or Scam. We Respect All Country
          Rules/Laws. If You Not Agree With Our Site Disclaimer. Please Quit Our
          Site Right Now. Thanks{" "}
        </p>
      </div>
    </>
  );
};

export default AboutUs;
